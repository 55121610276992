import React from "react";

import HelpCenterItem from "../../containers/HelpCenter/HelpCenterItem";

const HelpCenterArticle = ({ path }) => {
  return (
    <HelpCenterItem path={path}>
      <div>
        <p>
          Tobo offers three different subscription plans: monthly, quarterly and
          yearly renewal.
        </p>
        <p>
          When you subscribe to Tobo, you have ad-free learning experience, you
          unlock all app content immediately without having to reach point
          goals, you are able to sync your progress across your devices and you
          get unlimited hints for the in-app games (games only exists in some
          languages).
        </p>
        <p>
          You can use Tobo for free with ads and you can still unlock the app
          content by earning points.
        </p>
      </div>
    </HelpCenterItem>
  );
};

export default HelpCenterArticle;
